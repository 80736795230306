import styles from "./index.module.css";

export const getHeaderWithSubHeader = (heading, subHeading, classes = {}) => {
  return (
    <div
      className={
        classes.container ? classes.container : styles.glassHalfFullContainer
      }
    >
      <h3
        className={classes.header ? classes.header : styles.glassHalfFullHeader}
      >
        {heading}
      </h3>
      <p
        className={
          classes.subHeader ? classes.subHeader : styles.glassHalfFullSubHeading
        }
      >
        {subHeading}
      </p>
    </div>
  );
};
