// styles
import styles from "../index.module.css";

// constants
import { VISION_MISSION_GOAL } from "../../../constants/home.constants";
import VisionMissionGoalCard from "../../../components/design/Cards/VisionMissionGoal";

const VisionMissionGoal = () => {
  return (
    <section className={`${styles.visionMissionGoalSection} blackBg-container`}>
      {VISION_MISSION_GOAL.map(card => (
        <VisionMissionGoalCard
            key={card.id}
            cardHeader={card.title}
            cardDescription={card.description}
            cardImage={card.image}
        />
      ))}
    </section>
  );
};

export default VisionMissionGoal;
