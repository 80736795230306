import styles from "../index.module.css";

const HeroSection = () => {
  return (
    <div className={`${styles.heroSection} blackBg-container`}>
      <div className={styles.heroBody}>
        <h1 className={styles.heroTitle}>
          Saving water,
          <br />
          <span className={`${styles.subHeadHero} ${styles.heroTextHighlight}`}>
            Half a glass at a time
          </span>
        </h1>
        <p className={styles.heroText}>
          WE PROVIDE
          <span className={styles.greenHighLight}>
            {" "}
            SIMPLE SOLUTIONS TO SOLVE THE GLOBAL WATER CRISIS
          </span>
        </p>
      </div>
    </div>
  );
};

export default HeroSection;
