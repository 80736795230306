import Pavithra from "../assets/StoryBook/pavithra.jpg";
import Riley from "../assets/StoryBook/riley.jpg";
import Harshika from "../assets/StoryBook/harshika.jpg";
import Bhumija from "../assets/StoryBook/bhumija.jpg";
import Divya from "../assets/StoryBook/divya.jpg";
import Anvita from "../assets/StoryBook/anvita.jpg";
import Shruti from "../assets/StoryBook/shruti.jpg";
import HowToStart from "../assets/OtherResources/howtostart.png";
import Toolkit from "../assets/OtherResources/toolkit.jpeg";
import Restaurant from "../assets/OtherResources/restaurant.png";

export const ABOUT_BOOK = {
  title: "THE SUSTAINABILITY STORIES",
  subHeading: "August 14, 2020 / By Gaurang",
  content:
    "Why Waste? has now launched its first book – The Sustainability Stories, available on <a target='_blank' href='https://www.amazon.in/Sustainability-Stories-inspire-conscious-citizens-ebook/dp/B08FCSBYQD/ref=sr_1_1?dchild=1&keywords=sustainability+stories&qid=1597334561&sr=8-1'>Amazon</a>, for you to pick up on your Kindle! Focused on making young children realise the importance of the environment, and the horrendous problems it faces, the wonderful <a href='/resources/story-book/creators-behind'>creators</a> at Why Waste?, have put together some wonderful short stories, with colourful illustrations, and deep meanings. As we progress towards the future, it is imporant for the ones who are going to create the future, to realise these learnings early on, to be able to make positive changes in the world. The team at Why Waste? has worked hard and fast, to make the book the beautiful creation that it is now.<br/>Find the Foreword of the book, written by our Founder, Garvita, where she pens down her thoughts on the book on the <a href='/resources/story-book/foreward'>previous blog post</a>.",
};

export const FOREWARD_BOOK = {
  title: "THE SUSTAINABILITY STORIES – FOREWORD",
  subHeading: "August 14, 2020 / By Garvita",
  content: `I remember celebrating more birthdays at orphanages than at parties – Always having to finish
  all the food that I took on my plate. Through my early life, I learnt to empathize and enforce
  positive habits. The social sensitivity in me comes from practices like these, inculcated through my
  formative years. Understanding how crucial those experiences were, I conceptualised this book.
  Through this book, we hope children inculcate these same values from a very young age, in a way
  best know to them – through fables and fairytales.<br/>
  We are at a point where the world is now restructuring and reconsidering what the youth learn.
  We at Why Waste? decided to take a step towards this by introducing stories that inspire our
  generation to not just love our planet, but also work towards a more holistic and sustainable
  world. Thereby, shaping our futures.<br/>
  This book is a series of fables that hope to help shape young minds to become eco-conscious.
  “Breaking the glass ceiling and not trying to fit into the glass slipper.” That’s what we believe in.
  I founded Why Waste? in 2015 as an initiative to change the mindsets of people towards natural
  resources. Why Waste? is a team of like-minded youth who are taking water positivity to the world
  stage by means of innovative education and ideas that bring about systemic change. We have
  volunteers leading this movement across the globe, over 4 different continents.<br/>
  Why Waste? was recognised as an “Ashoka Youth Venture” in 2016. Recently, we became India’s
  largest youth-led movement (organisation) working towards water conservation. We were named
  “Water Heroes” by Republic Media Network in a collaborative initiative with the Govt of India
  (Ministry of Jal Shakti).<br/>
  We hope to encourage the youth. Your voice matters and development doesn’t come only
  with books. Wrapped in fiction and laced with morals, this book has more to it than the cover
  offers! I would like to thank the entire creative team of writers & designers for putting together a
  wonderful collection of inspiring stories.<br/>
  Every single story talks about empowering the young minds, who chose to take lead and make
  a difference. Simple stories like these can change the way the youth of our world grow up and
  shape their mindsets. Taking instances of water preservation, these stories hope to imbibe a
  conservationist mindset in our future generations,<br/>
  After all, tomorrow may be far, but what we make of our children today is what we will
  see tomorrow.<br/><br/>
  
  – Garvita Gulhati, Founder, Why Waste? `,
};

export const AUTHORS_BOOK = {
  title: "THE CREATORS BEHIND ‘THE SUSTAINABILITY STORIES’",
  subHeading: "August 14, 2020 / By Why Waste?",
  content: `We at Why Waste? are extremely delighted to annouce the launch of our first book, <a target='_blank' href='https://www.amazon.in/Sustainability-Stories-inspire-conscious-citizens-ebook/dp/B08FCSBYQD/ref=sr_1_1?dchild=1&keywords=sustainability+stories&qid=1597334561&sr=8-1'>The Sustainability Stories</a>, a colourful and fun book, filled with a bunch of delightful stories, each revealing an important message to the reader. Created to raise awareness in younger groups, about the problems the environment faces, the book contains various glorious illustrations, and a lot of values to take home.<br/>
  Today, we’d like to introduce the people who have worked hard and fast, to get this book to where it is now.`,
};

export const AUTHORS_LIST = [
  {
    id: 0,
    img: Pavithra,
    name: "Pavithra Rajesh",
    content:
      "Pavithra Rajesh is a second-year student at Northeastern University, currently pursuing her undergraduate degree in Journalism with minors in Theater and Musical Theater. Although she was born in Chennai, India, she spent her life moving around and has lived in seven different cities across the world and has attended six different schools. An avid writer, Pavithra enjoys storytelling in all forms, whether it’s through print reporting, video, audio, playwriting, or fiction novels. She continuously attempts to merge social justice opportunities into her line of work, as she has done through her contribution to Why Waste?",
  },
  {
    id: 1,
    img: Riley,
    name: "Riley Damiano",
    content:
      "Riley Damiano is from New York and attends Rhodes College in Memphis, Tennessee where she plans to study Neuroscience as part of the Class of 2024. She is incredibly passionate about helping young people discover their own passion for changemaking. For her personally, she has found her passion to be helping pediatric cancer warriors and their families through her initiative The Blue Lollipop Project. Riley has shared her own changemaking passion and encouraged others to get involved in changemaking through a TEDEd video, speaking at Facebook Headquarters, and in a video that currently has over five million views on YouTube.",
  },
  {
    id: 2,
    img: Harshika,
    name: "Harshika G",
    content:
      "Harshika G is an engineering student from NMIT. She enjoys illustrating different concepts using digital art and sketching. She is an empath who is highly passionate about empowering children, which led her to volunteer in Vietnam where she educated, empowered, and inspired young children. Apart from her philanthropic pursuits, she is an avid traveller who enjoys exploring various cultures from all over the world. She is an outgoing individual who enjoys public speaking and meeting new people. She is a fun loving ambitious person who has set out to make a positive impact on this world.",
  },
  {
    id: 3,
    img: Bhumija,
    name: "Bhumija Rishi",
    content:
      "Bhumija Rishi is a final year undergraduate student of Sociology at Lady Shri Ram College For Women, University of Delhi. She has a plethora of interests ranging from cinema , creative writing, public speaking and aspires to reach her human potential to its zenith. Her writing abilities are broad enough to encompass current affairs, environmental concerns, human resources and the ever growing culture of student entrepreneurship.",
  },
  {
    id: 4,
    img: Divya,
    name: "Divya Ramesh",
    content:
      "Doing her undergraduate in Electronics and Communication field of engineering, Divya Ramesh is a bibliophile. Forever having the greatest adventures amidst books. She has been a reviewer and has the pen, for a best friend as she creates art through writing. She further indulges in public speaking, singing, cooking and coding due to personal interest.",
  },
  {
    id: 5,
    img: Anvita,
    name: "Anvita Nair",
    content:
      "Anvita Nair is a an engineering student and mental health advocate. She identifies as a pun-loving bibliophile, who loves to spend her time reading and writing. As a firm believer in changemaking, she started her own initiative on youth mental health, Patronus, and is headlining the #BeatTheBlues campaign on maternal mental health in parallel. <br/><br/>During her stint as Co Lead and Editorial Head at Why Waste?, Anvita edited The Sustainability Stories, a short story book that encourages values of water positivity in children.",
  },
  {
    id: 6,
    img: Shruti,
    name: "Shruti Parthasarathy",
    content:
      "Shruti Parthasarathy finds it hard to describe herself. She is a writer, photographer, and a do-er. She believes that everything one does is a reflection of the people, place and time one lives in. With a passion for the environment and the sustainable way of living she has her heart in multiple domains. She explores narratives through what she creates and practices simplicity and sustainability.",
  },
  {
    id: 7,
    name: "Rhea Nandy",
    content:
      "Rhea is a graphic designer based in Singapore. Rhea constructs unique and elegant responses to creative problems and crafts visual experiences in branding and promotion, publication design, creative copy and illustration.",
  },
  {
    id: 8,
    name: "Presley Damiano",
    content:
      "Presley Damiano is a fifteen-year-old sophomore who attends Wooster School in Danbury, CT. Presley lives in New York. She loves to play soccer, basketball, and even competes in CrossFit. Her favorite subjects are English and Math. In addition, Presley is in a journalism class. In her free time, she likes to play with her dog, be outside, and hang out with her friends. ",
  },
];

export const OTHER_RESOURCES = [
  {
    id: "diyModule",
    img: Toolkit,
    title: "DIY Module",
    subHeading: "A toolkit on How Can You Help? - WhyWaste? In Your City",
    download: {
      multipleLanguge: true,
      multipleLangugeText: "Now you can download it in your language!",
      support: {
        Bangla:
          "https://drive.google.com/uc?export=download&id=1X35sUMEjZMcykQGgMcX-TAxQmRcvXAKB",
        Chinese:
          "https://drive.google.com/uc?export=download&id=1sJXhXhN-biv5TVUQcv5doyt1GmlU3kDg",
        English:
          "https://drive.google.com/uc?export=download&id=1H7UiXOGTaaWu8Jmn1dEBip6YAfGuUm3x",
        French:
          "https://drive.google.com/uc?export=download&id=1Vs-avqGrBPfhks5hVZ_kVdj-NCjNSys6",
        Gujarati:
          "https://drive.google.com/uc?export=download&id=1L3Rgq06Rya-4nGBGangTneQTkowJR1We",
        Hindi:
          "https://drive.google.com/uc?export=download&id=10iZd6sxq9HrFgPR3qHOm1hPOMZRAOixl",
        Japanese:
          "https://drive.google.com/uc?export=download&id=1esr-qw4gsjaHZJxGUwQJVR2-th_0eCF8",
        Javanese:
          "https://drive.google.com/uc?export=download&id=1-iubaQU1W67vZFmTv3S234PTv9XSc-ne",
        Kannada:
          "https://drive.google.com/uc?export=download&id=1GkW7hG5WWsD9R238v7dp-WTJSUyuv7Hz",
        Korean:
          "https://drive.google.com/uc?export=download&id=1EeXAia7Sh1OJCBfPL6OOCJ4EyWEGBY6m",
        Malayalam:
          "https://drive.google.com/uc?export=download&id=1YWtLDKBf_Ek92nBb9YxKsuARvukd2CT_",
        Marathi:
          "https://drive.google.com/uc?export=download&id=1XxHaEpkzvrK5j3aDPd47vkGJAraZ-Msx",
        Portugese:
          "https://drive.google.com/uc?export=download&id=1JNGTOusj3OUBiIMNMUDr7oF-KnPRWuI2",
        Punjabi:
          "https://drive.google.com/uc?export=download&id=1eu-Fy7O1_xK7McvOF6OkqRqNmiRC8opD",
        Russian:
          "https://drive.google.com/uc?export=download&id=178Mnr5V_ss44es4QtV7nOVj56CYKpAdS",
        Spanish:
          "https://drive.google.com/uc?export=download&id=1leIODLOV3SXTL5iVyYuuauFB7A89aacn",
        Tamil:
          "https://drive.google.com/uc?export=download&id=1zedCly6ZwKl8BpEXgrg6TozSdwH_Adjg",
        Telugu:
          "https://drive.google.com/uc?export=download&id=1AxAhWos4rH2QYwATPon7XLib-IPJczfL",
        Urdu: "https://drive.google.com/uc?export=download&id=1kQX2jBGN_BvpL6nN94ktBftjyHZlzT2b",
        Vietnamese:
          "https://drive.google.com/uc?export=download&id=1UBKTuEUTmT4XZ3BePRO1ztIf2LJeD5eY",
      },
    },
  },
  {
    id: "tipsModule",
    img: HowToStart,
    title: "How To Save Hundred Litres Of Water Daily",
    subHeading: "Tips and tricks to save water in daily life.",
    download: {
      multipleLanguge: false,
      link: "https://drive.google.com/uc?export=download&id=1JmG-Fgejy4AhMa8o4jiFFEm-zR615qEO",
    },
  },
  {
    id: "restaurantModule",
    img: Restaurant,
    title: "Restaurant Module",
    subHeading: "Some guidelines to save water in restaurants.",
    download: {
      multipleLanguge: false,
      link: "https://drive.google.com/uc?export=download&id=1rk3Q_fa07mXaT9f4Eefw9n2rNGLQY8m7",
    },
  },
];
