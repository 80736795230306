// components
import HeroSection from "./HeroSection";
import VisionMissionGoal from "./VisionMissionGoal";
import GlassHalfFullSlider from "./GlassHalfFullSlider";
import WaterFootPrint from "./WaterFootPrint";
import WeHome from "./We";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <WaterFootPrint />
      <WeHome />
      <GlassHalfFullSlider />
      <VisionMissionGoal />
    </div>
  );
};

export default Home;
