export function entirepath(...routes) {
  if (routes.length === 1) {
    return `/${routes[0]}`;
  }

  let entireRoute = "/";
  routes.forEach((route) => {
    entireRoute += `${route}/`;
  });
  return entireRoute;
}
