// components
import WeHomeCard from "../../../components/design/Cards/WeHome/index";

// constants
import { WE_HOME } from "../../../constants/home.constants";

// styles
import styles from "../index.module.css";

const WeHome = () => {
  return (
    <section className={styles.weHomeContainer}>
      {WE_HOME.map((card, count) => (
        <WeHomeCard
          key={card.id}
          title={card.title}
          image={card.image}
          description={card.description}
          count={count}
        />
      ))}
    </section>
  );
};

export default WeHome;
