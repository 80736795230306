import Router from "./components/utils/router";
import Home from "./pages/Home";
import ScrollToTop from "./components/utils/router/ScrollToTop";

function App() {
  return (
    <div>
      <ScrollToTop />
      <Router />
    </div>
  );
}

export default App;
