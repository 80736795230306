import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// styles
import styles from "./index.module.css";

// icons
import { GiHamburgerMenu } from "react-icons/gi";

// components
import NavLink from "./NavLink";
import Button from "../Button";
import Drawer from "@mui/material/Drawer";
import Menu from "./Menu";
import Divider from "@mui/material/Divider";

// constants
import { exactRoutes } from "../../../constants/routes";

const MainNavbar = ({ navLinks, button, buttonArr, logo }) => {
  const navigate = useNavigate();
  const [selectedPath, setSelectedPath] = useState(exactRoutes.BASE);
  const [showDrawer, setShowDrawer] = useState(false);
  useEffect(() => {
    const path = window.location.pathname;
    const pathArr = path.split("/");
    setSelectedPath(exactRoutes.BASE + pathArr[1]);
  }, [window.location.pathname]);

  return (
    <header className={`blackBg-container ${styles.container}`}>
      <img
        src={logo}
        alt="WhyWaste Logo"
        onClick={() => navigate("/")}
        className={styles.logo}
      />
      <div className={styles.rightNavContainer}>
        <nav className={styles.rightNavContainer}>
          {navLinks.map((link, idx) => (
            <NavLink
              key={idx}
              name={link.name}
              path={link.path}
              dropDown={link.dropDown}
              children={link.children}
              selectedPath={selectedPath}
            />
          ))}
        </nav>
        {button &&
          buttonArr.map((button, idx) => (
            <Button
              key={idx}
              text={button.text}
              onClick={button.onClick}
              classes={styles.button}
            />
          ))}
      </div>
      <GiHamburgerMenu
        className={styles.menuIcon}
        onClick={() => setShowDrawer(true)}
      />
      <Drawer
        anchor="left"
        open={showDrawer}
        classes={{ paper: styles.drawer }}
        onClose={() => setShowDrawer(false)}
      >
        <Menu items={navLinks} setShowDrawer={setShowDrawer} />
        <Divider />
        {button &&
          buttonArr.map((button, idx) => (
            <Button
              key={idx}
              text={button.text}
              onClick={button.onClick}
              classes={styles.button}
              customStyles={{ marginTop: "10px" }}
            />
          ))}
      </Drawer>
    </header>
  );
};

export default MainNavbar;
