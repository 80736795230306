import React from "react";
import { getHeaderWithSubHeader } from "../../Home.utility";

// styles
import styles from "../../index.module.css";

const BetterIndiaChennai = ({ header, body, hashtag, link, img }) => {
  return (
    <div className={styles.betterIndiaContainer}>
      {getHeaderWithSubHeader(header.header, header.subHeader)}
      <div
        className={`${img && styles.imgContainer} ${
          styles.mainContainerBetterIndia
        }`}
      >
        {img && (
          <div>
            <img
              src={img.src}
              alt={img.alt}
              className={styles.imgBetterIndia}
            />
          </div>
        )}
        <div className={img && styles.betterIndiaContent}>
          <p className={styles.contentBetterIndia}>{body}</p>
          {link && (
            <a
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.linkToReadMoreBetterIndia}
            >
              {link.text}
            </a>
          )}
        </div>
      </div>
      <div className={styles.hashTagBetterIndia}>{hashtag}</div>
    </div>
  );
};

export default BetterIndiaChennai;
