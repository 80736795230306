import React from "react";

// components
import List from "@mui/material/List";
import NestedMenuItem from "./NestedMenuItem";
import SingleMenuItem from "./SingleMenuItem";

/**
 *
 * @param {Array} items An array of objects containing the menu item data
 * @returns Menu items
 */
const Menu = ({ items, setShowDrawer }) => {
  return (
    <List>
      {items.map((item, index) => {
        if (item.children) {
          return (
            <NestedMenuItem
              key={index}
              data={item}
              setShowDrawer={setShowDrawer}
            />
          );
        }
        return (
          <SingleMenuItem
            key={index}
            data={item}
            setShowDrawer={setShowDrawer}
          />
        );
      })}
    </List>
  );
};

export default Menu;
