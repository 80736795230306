import styles from "../index.module.css";

const WeHomeCard = ({ title, image, description, count }) => {
  return (
    <div
      className={`${styles.weHome} ${
        count % 2 === 0 ? styles.weHomeDark : styles.weHomeBlue
      }`}
    >
      <div className={styles.weHomeImage}>
        <img src={image.src} alt={image.alt} />
      </div>
      <div
        className={`${styles.weHomeTitle} ${
          count % 2 === 0 ? styles.weHomeTitleLight : styles.weHomeTitleDark
        }`}
      >
        <h3>{title}</h3>
      </div>
      <div
        className={`${styles.weHomeDescription} ${
          count % 2 === 0
            ? styles.weHomeDescriptionBlue
            : styles.weHomeDescriptionWhite
        }`}
      >
        <p>{description}</p>
      </div>
    </div>
  );
};

export default WeHomeCard;
