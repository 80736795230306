import VISION from "../assets/Home/vision.svg";
import MISSION from "../assets/Home/mission.svg";
import GOAL from "../assets/Home/goal.svg";
import WeBuild from "../assets/Home/WeBuild.svg";
import WeEmphatize from "../assets/Home/WeEmphatize.svg";
import WeEducate from "../assets/Home/WeEducate.svg";
import WeInnovate from "../assets/Home/WeInnovate.svg";
import RESTAURANT from "../assets/Home/restaurant.svg";
import PEOPLE from "../assets/Home/people.svg";
import WATER from "../assets/Home/water.svg";
import CHENNAI_BETTER_INDIA from "../assets/Home/imageBetterIndiaChennai.png";
import RahulChopra from "../assets/Home/RahulChopra.svg";
import GauravJain from "../assets/Home/GauravJain.svg";

export const GLASS_HALF_FULL = [
  {
    id: "restaurant",
    title: "1,50,000+",
    description: "Restaurants involved",
    image: {
      src: RESTAURANT,
      alt: "Restaurant",
    },
  },
  {
    id: "people",
    title: "20,00,000+",
    description: "People reached out",
    image: {
      src: PEOPLE,
      alt: "People",
    },
  },
  {
    id: "water",
    title: "10,00,000+",
    description: "Litres of water conserved",
    image: {
      src: WATER,
      alt: "Water",
    },
  },
];

export const CHENNAI_NOT_CAPE_TOWN = [
  {
    id: "chennai",
    header: {
      header: "BETTER INDIA, CHENNAI",
      subHeader:
        "Helping Chennai get back on its feet when the taps ran dry in the year of 2019.",
    },
    img: {
      src: CHENNAI_BETTER_INDIA,
      alt: "Better India Chennai",
    },
    description:
      "In 2019 summer, as the Chennai water crisis inched towards its peak, 20 year old Gaurav Jain decided it was time to make a difference. Gaurav’s story of making the most of the two months of his semester break back home is a story worth sharing.",
    hashtag: "#ChennaiNotCapeTown",
    // link: {
    //   url: "https://www.google.com",
    //   text: "Read More",
    // },
  },
  {
    id: "hopeSchooling",
    header: {
      header: "HOPE SCHOOLING",
      subHeader: null,
    },
    description: `A series of webinars was started during Covid times on the theme of climate hope. Panellists were invited to exchange views on topics from “Planetary Policy Changes” to “An Artist’s Outlook to Combating Climate Change”`,
    hashtag: "#ChennaiNotCapeTown",
    // link: {
    //   url: "https://www.google.com",
    //   text: "Read More",
    // },
  },
];

export const VISION_MISSION_GOAL = [
  {
    id: "vision",
    title: "Vision",
    description:
      "To optimize the usage and prevent the wastage of water to tackle the global water crisis.",
    image: {
      src: VISION,
      alt: "Vision",
    },
  },
  {
    id: "mission",
    title: "Mission",
    description:
      "To change the mindsets of people towards water by actionable insights and solution-driven systems change.",
    image: {
      src: MISSION,
      alt: "Mission",
    },
  },
  {
    id: "goal",
    title: "Goal",
    description:
      "To inspire every individual to feel responsible and to take action towards the conservation of water.",
    image: {
      src: GOAL,
      alt: "Goal",
    },
  },
];

export const WE_HOME = [
  {
    id: "weEmphatize",
    title: "WE EMPHATIZE",
    description: "we understand the problem.",
    image: {
      src: WeEmphatize,
      alt: "We Emphatize",
    },
  },
  {
    id: "weInnovate",
    title: "WE INNOVATE",
    description: "we solve the probelm.",
    image: {
      src: WeInnovate,
      alt: "We Innovate",
    },
  },
  {
    id: "weEducate",
    title: "WE EDUCATE",
    description: "we take the solution to the people.",
    image: {
      src: WeEducate,
      alt: "We Educate",
    },
  },
  {
    id: "weBuild",
    title: "WE BUILD",
    description: "we build impact.",
    image: {
      src: WeBuild,
      alt: "We Build",
    },
  },
];
