import { useRef, useState } from "react";

// Swiper Element
import SwiperElement from "./SwiperElement";

// styles
import styles from "./index.module.css";
import Button from "../Button";

// icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";

const Swiper = ({ slides }) => {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className={styles.swiperContainer}>
      {activeIndex !== 0 && (
        <div>
          <Button
            text={
              <FontAwesomeIcon
                icon={faAngleLeft}
                className={styles.swiperButtonIcon}
                style={{
                  visibility: activeIndex !== 0 ? "visible" : "hidden",
                }}
              />
            }
            onClick={() => swiperRef.current.slidePrev()}
            classes={styles.swiperButton}
          />
        </div>
      )}
      <div className={styles.swiperContentContainer}>
        <SwiperElement
          swiperRef={swiperRef}
          slides={slides}
          setActiveIndex={setActiveIndex}
        />
      </div>
      {activeIndex !== slides.length - 1 && (
        <div>
          <Button
            text={
              <FontAwesomeIcon
                icon={faAngleRight}
                className={styles.swiperButtonIcon}
                style={{
                  visibility:
                    activeIndex !== slides.length - 1 ? "visible" : "hidden",
                }}
              />
            }
            onClick={() => {
              swiperRef.current.slideNext();
            }}
            classes={styles.swiperButton}
          />
        </div>
      )}
    </div>
  );
};

export default Swiper;
