import { exactRoutes } from "./routes";

// add support for icons and buttons and redirections to other pages and child pages as well
// you can add a property icon in each object and you can also add dropDown true or false and then pass children array to add dropDown items

const basicNavLinks = [
  {
    name: "Home",
    path: exactRoutes.BASE,
  },
  {
    name: "Team",
    path: exactRoutes.TEAM,
  },
  // {
  //   name: "About",
  //   path: exactRoutes.ABOUT,
  //   dropDown: true,
  //   children: [
  //     {
  //       name: "Test",
  //       path: exactRoutes.TEST,
  //     },
  //   ],
  // },
  {
    name: "Resources",
    path: exactRoutes.RESOURCES,
    dropDown: true,
    children: [
      {
        name: "WhyWaste? App",
        path: exactRoutes.APP_DOWNLOAD,
      },
      {
        name: "Story Book",
        path: exactRoutes.STORY_BOOK,
      },
      {
        name: "Other Resources",
        path: exactRoutes.OTHER_RESOURCES,
      },
    ],
  },
];

export const signedOutNavLinks = [...basicNavLinks];
