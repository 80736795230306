import styles from "../index.module.css";
import { Link } from "react-router-dom";

// icons
import { FaChevronDown } from "react-icons/fa";

const NavLink = ({
  name,
  path,
  selectedPath,
  dropDown = false,
  children = [],
  style = {},
}) => {
  if (!dropDown)
    return (
      <Link
        to={path}
        style={style}
        className={`${styles.navLink} ${
          selectedPath === path ? styles.highLightBorder : ""
        }`}
      >
        {name}
      </Link>
    );
  return (
    <div
      className={`${styles.dropDownContainer} ${
        selectedPath === path ? styles.highLightBorder : ""
      }`}
    >
      <span className={styles.dropDownLinkName}>{name}</span>
      <FaChevronDown size={"10px"} />
      <div className={styles.dropDown}>
        {children.map((child, idx) => (
          <Link key={idx} to={child.path} style={style}>
            {child.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavLink;
