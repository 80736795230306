// styles
import styles from "../index.module.css";

/**
 *
 * @param {String} cardHeader Contains the header of the card
 * @param {Object} cardImage Contains the image of the card with alt and src
 * @param {String} cardDescription Contains the description of the card
 * @param {Object} cardStyles Contains the styles of the card with parent, image, header, and description
 * @returns a card with the header, image, and description
 * @author ammrit2312 <amriteshc101@icloud.com>
 */
const VisionMissionGoalCard = ({
  cardHeader,
  cardImage,
  cardDescription,
  cardStyles,
}) => {
  return (
    <div
      className={
        cardStyles?.parent ? cardStyles.parent : styles.visionMissionGoalParent
      }
    >
      <img
        src={cardImage.src}
        alt={cardImage.alt}
        className={
          cardStyles?.image ? cardStyles.image : styles.visionMissionGoalImage
        }
      />
      <h3
        className={
          cardStyles?.header
            ? cardStyles.header
            : styles.visionMissionGoalHeader
        }
      >
        {cardHeader}
      </h3>
      <p
        className={
          cardStyles?.description
            ? cardStyles.description
            : styles.visionMissionGoalDescription
        }
      >
        {cardDescription}
      </p>
    </div>
  );
};

export default VisionMissionGoalCard;
