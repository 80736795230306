import Swiper from "../../../components/design/Swiper";
import BetterIndiaChennai from "./BetterIndiaChennai";
import GlassHalfFull from "./GlassHalfFull";

// constants
import { CHENNAI_NOT_CAPE_TOWN } from "../../../constants/home.constants";

// styles
import styles from "../index.module.css";

const GlassHalfFullSlider = () => {
  const slides = [
    <GlassHalfFull />,
    <BetterIndiaChennai
      header={CHENNAI_NOT_CAPE_TOWN[0].header}
      body={CHENNAI_NOT_CAPE_TOWN[0].description}
      hashtag={CHENNAI_NOT_CAPE_TOWN[0].hashtag}
      link={CHENNAI_NOT_CAPE_TOWN[0].link}
      img={CHENNAI_NOT_CAPE_TOWN[0].img}
    />,
    <BetterIndiaChennai
      header={CHENNAI_NOT_CAPE_TOWN[1].header}
      body={CHENNAI_NOT_CAPE_TOWN[1].description}
      hashtag={CHENNAI_NOT_CAPE_TOWN[1].hashtag}
      link={CHENNAI_NOT_CAPE_TOWN[1].link}
    />,
  ];
  return (
    <section className={styles.glassHalfFullMainContainer}>
      <Swiper slides={slides} />
    </section>
  );
};

export default GlassHalfFullSlider;
