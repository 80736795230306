import { Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

// Swiper styles
import "swiper/css";
import "swiper/css/virtual";

const SwiperElement = ({
  swiperRef,
  slides,
  propsForSwiper = {
    spaceBetween: 0,
    slidesPerView: 1,
    virtual: true,
    breakpoints: {},
  },
  setActiveIndex,
}) => {
  const onSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };
  return (
    <Swiper
      modules={[Virtual]}
      {...propsForSwiper}
      onSlideChange={onSlideChange}
      onSwiper={(swiper) => {
        swiperRef.current = swiper;
        setActiveIndex(swiper.activeIndex);
      }}
      style={{ width: "100%", margin: "0 1rem" }}
    >
      {slides.map((slideContent, index) => (
        <SwiperSlide key={slideContent} virtualIndex={index}>
          {({ isActive }) => (
            <div style={{ height: !isActive ? "0" : "auto" }}>
              {slideContent}
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperElement;
