import { Outlet } from "react-router-dom";

// Components
import MainNavbar from "../../design/Navbar/MainNavbar";

// Assets
import logo from "../../../assets/Logos/navBarWhyWasteLogo.svg";

import { useNavigate } from "react-router-dom";
import MainFooter from "../../design/Footer/MainFooter";

const MainLayout = ({ navLinks }) => {
  let navigate = useNavigate();
  const buttonArr = [
    {
      text: "Contact",
      onClick: () => {
        navigate("/contact-us");
      }
    },
  ];
  return (
    <main>
      <MainNavbar
        navLinks={navLinks}
        button={true}
        buttonArr={buttonArr}
        logo={logo}
      />
      <Outlet />
      <MainFooter logo={logo} />
    </main>
  );
};

export default MainLayout;
