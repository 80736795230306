import { entirepath } from "../utils/routes.utils";

export const routes = {
  TEAM: "team",
  NOT_FOUND: "404",
  ABOUT: "about",
  TEST: "test",
  CONTACT_US: "contact-us",
  RESOURCES: "resources",
  STORY_BOOK: "story-book",
  FOREWARD: "foreward",
  CREATORS_BEHIND: "creators-behind",
  OTHER_RESOURCES: "other-resources",
  APP_DOWNLOAD: "app-download",
};

export const exactRoutes = {
  BASE: "/",
  TEAM: entirepath(routes.TEAM),
  NOT_FOUND: entirepath(routes.NOT_FOUND),
  ABOUT: entirepath(routes.ABOUT),
  TEST: entirepath(routes.ABOUT, routes.TEST),
  CONTACT_US: entirepath(routes.CONTACT_US),
  RESOURCES: entirepath(routes.RESOURCES),
  STORY_BOOK: entirepath(routes.RESOURCES, routes.STORY_BOOK),
  OTHER_RESOURCES: entirepath(routes.RESOURCES, routes.OTHER_RESOURCES),
  APP_DOWNLOAD: entirepath(routes.RESOURCES, routes.APP_DOWNLOAD),
  FOREWARD_BOOK: entirepath(
    routes.RESOURCES,
    routes.STORY_BOOK,
    routes.FOREWARD
  ),
  CREATORS_BEHIND: entirepath(
    routes.RESOURCES,
    routes.STORY_BOOK,
    routes.CREATORS_BEHIND
  ),
  ABOUT_BOOK: entirepath(routes.RESOURCES, routes.STORY_BOOK, routes.ABOUT),
};
