export const FOOTER = "footer";
export const CONTACT_US = "CONTACT_US";

export const FOOTER_MAILTO = {
  subject: "Connect with Why Waste?",
  body: "Hi, I would like to connect with you.",
};

export const AMAZON_STORY_BOOK_LINK =
  "https://www.amazon.in/Sustainability-Stories-inspire-conscious-citizens-ebook/dp/B08FCSBYQD/ref=sr_1_1?dchild=1&keywords=sustainability+stories&qid=1597334561&sr=8-1";

export const READ_STORY_BOOK =
  "https://storyweaver.org.in/en/publishers/4116-why-waste";

export const APP_STORE = "https://apps.apple.com/in/app/why-waste/id1530519500";

export const APP_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=whywasteorg.wwapp";

export const GOOGLE_DRIVE_DOWNLOAD = "https://drive.google.com/uc?export=download&id=";

export const GOOGLE_DRIVE_LINK = "https://drive.google.com/thumbnail?id=";

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const LENGTH_VALIDATOR_512 = /^[a-zA-Z0-9 !@#$%^&*.)(]{2,512}$/;

export const API_BASE_URL = "https://api.whywaste.io/"
// export const API_BASE_URL = "http://localhost:8080/"