// styles
import styles from "../../index.module.css";

// constants
import { GLASS_HALF_FULL } from "../../../../constants/home.constants";

// components
import GlassHalfFullCard from "../../../../components/design/Cards/GlassHalfFull";
import { getHeaderWithSubHeader } from "../../Home.utility";

const GlassHalfFull = () => {
  return (
    <>
      {getHeaderWithSubHeader(
        "#GlassHalfFull",
        "directs restaurants to serve half-filled glasses to their customers"
      )}
      <section className={`${styles.glassHalfFullSection} whiteBg-container`}>
        {GLASS_HALF_FULL.map((card) => (
          <GlassHalfFullCard
            key={card.id}
            cardHeader={card.title}
            cardDescription={card.description}
            cardImage={card.image}
          />
        ))}
      </section>
    </>
  );
};

export default GlassHalfFull;
