import React, { useState } from "react";

// components
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SingleMenuItem from "../SingleMenuItem";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

/**
 *
 * @param {Oject} data An object containing the following properties:
 * {
 *    name: "Account",
 *    options : [
 *     {
 *      name: "Profile",
 *      path: "/profile"
 *    },
 *    {
 *      name: "Logout",
 *      path: "/logout"
 *    }
 *   ]
 * }
 * @returns Custom Nested MenuItem
 */
const NestedMenuItem = ({ data, setShowDrawer }) => {
  // state
  const [open, setOpen] = useState(false);

  const toggleCollapse = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={toggleCollapse}>
        <ListItemText primary={data.name} />
        {open ? <MdExpandLess /> : <MdExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data.children.map((item, index) => (
            <SingleMenuItem
              key={index}
              data={item}
              setShowDrawer={setShowDrawer}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NestedMenuItem;
