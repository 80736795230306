import React from "react";
import { useNavigate } from "react-router-dom";

// components
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

/**
 *
 * @param {Oject} data An object containing the following properties:
 * {
 *    name: "Account",
 *    path: "/account",
 * }
 * @returns Custom Single MenuItem
 */
const SingleMenuItem = ({ data, setShowDrawer }) => {
  // object
  const navigate = useNavigate();

  const onClick = () => {
    navigate(data.path);
    setShowDrawer(false);
  };

  return (
    <ListItemButton onClick={onClick}>
      <ListItemText primary={data.name} />
    </ListItemButton>
  );
};

export default SingleMenuItem;
