import moment from "moment";
import { useNavigate } from "react-router-dom";

// constants
import { FOOTER, FOOTER_MAILTO } from "../../../constants/index.constants";

// utils
import { generateSocialMediaArr } from "../../../utils/utility.utils";

// components
import Button from "../Button";
import Wave from "../Wave";

// styles
import styles from "./index.module.css";

const MainFooter = ({ logo }) => {
  const navigate = useNavigate();

  const iconComponent = generateSocialMediaArr(FOOTER);

  const onClick = async () => {
    window.location = `mailto:contact@whywaste.io?subject=${FOOTER_MAILTO.subject}&body=${FOOTER_MAILTO.body}`;
  };

  return (
    <footer className={`blackBg-container ${styles.container}`}>
      <div>
        <img
          src={logo}
          alt="WhyWaste Logo"
          onClick={() => navigate("/")}
          className={styles.logo}
        />
        <div>
          <p className={styles.followUs}>Follow us on</p>
          <div className={styles.iconComponent}>
            {iconComponent.map((item) => (
              <a
                key={item.id}
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </a>
            ))}
          </div>
          <p className={styles.complaintInfo}>
            Why Waste? is compliant to all PSEA regulations set out by the
            United Nations​
          </p>
        </div>
      </div>
      <div className={styles.connectWithUs}>
        <div>
          <p className={styles.connectText}>Connect with us!</p>
          <input type="text" placeholder="Email ID" className={styles.input} />
          <Button text={"Send"} onClick={onClick} classes={styles.button} />
        </div>
      </div>
      <div className={styles.copyright}>
        <div className={styles.termsDetails}>
          <a
            href="https://drive.google.com/file/d/15lEKXSznf6GBeBI2tyvcFKkr-z7RXJVZ/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{" "}
          |{" "}
          <a
            href="https://drive.google.com/file/d/1RnQhv-hJDEy-y4DLp4RePkz_s23XmbYJ/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Code of Conduct
          </a>
          |{" "}
          <a
            href="https://drive.google.com/file/d/1AkprILaQ-DNnbLUQbQJaG7xRlAebI1MB/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            PSEA Policy
          </a>
        </div>
        Copyright © {moment().year()} Why Waste? Organization
      </div>
    </footer>
  );
};

export default MainFooter;
