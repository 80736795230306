import { lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

// layouts
import MainLayout from "./MainLayout";

// constants
import { exactRoutes } from "../../../constants/routes";
import { signedOutNavLinks } from "../../../constants/navbar.constants";
import {
  ABOUT_BOOK,
  AUTHORS_BOOK,
  FOREWARD_BOOK,
} from "../../../constants/resources.constants";
import { generateStoryBookAuthors } from "../../../utils/utility.utils";

// components
import Loadable from "./Loadable";

const MainRouter = () => {
  return useRoutes([
    {
      path: exactRoutes.BASE,
      element: <MainLayout navLinks={signedOutNavLinks} />, // pass the navbar links to show in the layout
      children: [
        {
          element: <Home />,
          index: true,
        },
        {
          element: <TeamProfile />,
          path: exactRoutes.TEAM,
        },
        // {
        //   path: exactRoutes.TEST,
        //   element: <h1>Test</h1>,
        // },
        {
          path: exactRoutes.STORY_BOOK,
          element: <StoryBook />,
        },
        {
          path: exactRoutes.OTHER_RESOURCES,
          element: <OtherResources />,
        },
        {
          path: exactRoutes.APP_DOWNLOAD,
          element: <WhyWasteApp />,
        },
        {
          element: <InformationPage {...ABOUT_BOOK} />,
          path: exactRoutes.ABOUT_BOOK,
        },
        {
          element: <InformationPage {...FOREWARD_BOOK} />,
          path: exactRoutes.FOREWARD_BOOK,
        },
        {
          element: (
            <InformationPage
              {...AUTHORS_BOOK}
              extraJsxContent={generateStoryBookAuthors()}
            />
          ),
          path: exactRoutes.CREATORS_BEHIND,
        },
        {
          element: <ContactUs />,
          path: exactRoutes.CONTACT_US,
        },
        { path: exactRoutes.NOT_FOUND, element: <NotFoundPage /> },
      ],
    },
    { path: "*", element: <Navigate to={exactRoutes.NOT_FOUND} replace /> },
  ]);
};

const Home = Loadable(lazy(() => import("../../../pages/Home")));

const TeamProfile = Loadable(lazy(() => import("../../../pages/TeamProfile")));

const NotFoundPage = Loadable(lazy(() => import("../../../pages/404Page")));

const StoryBook = Loadable(lazy(() => import("../../../pages/StoryBook")));

const ContactUs = Loadable(lazy(() => import("../../../pages/ContactUs")));

const OtherResources = Loadable(
  lazy(() => import("../../../pages/OtherResources"))
);

const WhyWasteApp = Loadable(lazy(() => import("../../../pages/WhyWasteApp")));

const InformationPage = Loadable(
  lazy(() => import("../../../pages/InformationPage"))
);

export default MainRouter;
