// assets
import Foots from "../../../assets/homeLegWork.svg";
import Button from "../../../components/design/Button";

// utils
import showNotification from "../../../utils/notifications.utils";

// styles
import styles from "../index.module.css";

const WaterFootPrint = () => {
  return (
    <section className={styles.footPrintSection}>
      <div className={styles.leftFootContainer}>
        <p className={`${styles.footCalculatorBody} ${styles.greenHighLight}`}>
          A water footprint shows the extent of water use in relation to
          consumption by people.
        </p>
        <h2 className={styles.footCalculatorTitle}>Your Water Footprint</h2>
        <Button
          text="CALCULATE NOW!"
          onClick={() =>
            showNotification({
              title: "Feature Coming Soon!!!",
              type: "info",
            })
          }
        />
      </div>
      <div className={styles.rightFootContainer}>
        <img
          src={Foots}
          className={styles.footPrintsImage}
          alt="water foot print"
        />
      </div>
    </section>
  );
};

export default WaterFootPrint;
