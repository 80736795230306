// styles
import styles from "./index.module.css";

const Button = ({
  customStyles = {},
  onClick,
  text,
  classes,
  propsForButton,
}) => {
  return (
    <button
      className={classes ? classes : styles.button}
      style={customStyles}
      onClick={onClick}
      {...propsForButton}
    >
      {text}
    </button>
  );
};

export default Button;
