// styles
import styles from "../index.module.css";

/**
 *
 * @param {String} cardHeader Contains the header of the card
 * @param {Object} cardImage Contains the image of the card with alt and src
 * @param {String} cardDescription Contains the description of the card
 * @param {Object} cardStyles Contains the styles of the card with parent, image, header, and description
 * @returns a card with the header, image, and description
 * @author nidhiii13 <nidhinidhu1234@gmail.com>
 */
const GlassHalfFullCard = ({
  cardHeader,
  cardImage,
  cardDescription,
  cardStyles,
}) => {
  return (
    <div
      className={
        cardStyles?.parent ? cardStyles.parent : styles.glassHalfFullParent
      }
    >
      <img
        src={cardImage.src}
        alt={cardImage.alt}
        className={
          cardStyles?.image ? cardStyles.image : styles.glassHalfFullImage
        }
      />
      <h3
        className={
          cardStyles?.header
            ? cardStyles.header
            : styles.glassHalfFullHeader
        }
      >
        {cardHeader}
      </h3>
      <p
        className={
          cardStyles?.description
            ? cardStyles.description
            : styles.glassHalfFullDescription
        }
      >
        {cardDescription}
      </p>
    </div>
  );
};

export default GlassHalfFullCard;
