import parse from "html-react-parser";

// styles
import styles from "./index.module.css";

// icons
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";

// constants
import { CONTACT_US, FOOTER } from "../constants/index.constants";
import { AUTHORS_LIST } from "../constants/resources.constants";
import { GOOGLE_DRIVE_LINK } from "../constants/index.constants";

export const generateTeamMap = (teamArr) => {
  const teamMap = {
    ogs: [],
    "The Team Responsible for WhyWaste?'s success": [],
  };
  teamArr.forEach((member) => {
    if (member.showInTop4) teamMap.ogs.push(member);
    else teamMap["The Team Responsible for WhyWaste?'s success"].push(member);
  });
  teamMap["The Team Responsible for WhyWaste?'s success"] = teamMap[
    "The Team Responsible for WhyWaste?'s success"
  ].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  return teamMap;
};

export const generateSocialMediaArr = (screen) => {
  const iconComponent = [
    {
      id: "instagram",
      icon: <FaInstagram />,
      link: "https://www.instagram.com/whywasteorg/",
    },
    {
      id: "twitter",
      icon: <FaSquareTwitter />,
      link: "https://twitter.com/WhyWasteOrg",
    },
    {
      id: "linkedin",
      icon: <FaLinkedin />,
      link: "https://www.linkedin.com/company/whywasteorg/",
    },
    {
      id: "facebook",
      icon: <FaSquareFacebook />,
      link: "https://www.facebook.com/WhyWasteOrg/",
    },
  ];

  const youtube = {
    id: "youtube",
    icon: <FaYoutube />,
    link: "https://www.youtube.com/@whywaste",
  };

  switch (screen) {
    case CONTACT_US:
      return iconComponent;
    case FOOTER:
      return [...iconComponent, youtube];
    default:
      return iconComponent;
  }
};

export const openExternalLinkNewWindow = (
  link,
  propsToPass = { target: "_blank", rel: "noopener noreferrer" }
) => {
  window.open(link, { ...propsToPass });
};

export const generateStoryBookAuthors = () => {
  return (
    <div className={styles.storyBookAuthorMainContainer}>
      {AUTHORS_LIST.map((authorData, idx) => (
        <div key={authorData.id} className={styles.storyBookAuthorContainer}>
          {authorData.img && <img src={authorData.img} alt={authorData.name} />}
          <div>
            <p className={styles.storyBookAuthorName}>{authorData.name}</p>
            <p className={styles.storyBookAuthorContent}>
              {parse(authorData.content)}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export const getGoogleDriveId = (link) => {
  let linkArr;
  let fileId;

  // case - https://drive.google.com/open?id=1o71T2HGR57uYIutDhGlkkKEYDcVuU43C or https://drive.google.com/uc?export=download&id=someId
  if (link.includes("=")) {
    linkArr = link.split("=");
    fileId = linkArr[linkArr.length - 1];
    return fileId;
  }

  // case https://drive.google.com/file/d/1_sCoTpC95Z2YSUjGQb_oQkCCcvAExfbE/view?usp=sharing
  if (link.includes("sharing")) {
    linkArr = link.split("/");
    fileId = linkArr[linkArr.length - 2];
    return fileId;
  }
  return link;
};

export const generateGoogleDriveLink = (link) => {
  if (!link.includes("drive")) return link;
  return `${GOOGLE_DRIVE_LINK}${getGoogleDriveId(link)}`;
};
